<template>
  <div class="mintage">
    <div class="header">
      <!-- <img src="@/assets/img/base/i1.png" class="header-icon" /> -->
      <img src="@/assets/img/logo.png" class="header-icon" />
      <div class="title">{{ $t('铸币') }}</div>
    </div>

    <div class="consume">
      <div class="row">
        <img src="@/assets/img/base/usd.png" class="consume-icon" />
        <div class="consume-title">{{ $t('消耗') }}USD</div>
      </div>
      <div class="link" @click="tolink('ZhubiRecord')">{{ $t('铸币记录') }}</div>
    </div>

    <div class="form">
      <div class="input-wrap">
        <div class="input-label">{{ $t('数量') }}</div>
        <div class="input-row">
          <img src="@/assets/img/base/jian.png" class="stepper-icon" @click="reduce" />
          <div class="input">
            <q-input borderless v-model="jine" dense :placeholder="$t('请输入')"
              :input-style="{ textAlign: 'center', color: '#fff' }" />
          </div>
          <img src="@/assets/img/base/jia.png" class="stepper-icon" @click="jine++" />
        </div>
      </div>
      <div class="cell q-pt-sm">
        <div>{{ $t('可用') }}USD：{{ balance }}</div>
        <div class="mintage-text" @click="jine = balance">{{ $t('最大') }}</div>
      </div>
    </div>

    <div class="btn" @click="touzi">{{ $t('立即') }}{{ $t('铸币') }}</div>
  </div>
</template>

<script>
import { ref } from 'vue';
import Web3Helper from "@/assets/js/web3helper.js";
import CustomComponent from "@/components/CustomComponent";

import ExtractChineseWords from "@/assets/js/language/tool/extract-chinese-words.js";

export default {
  name: 'Touzi',
  components: {},
  setup() {
    return {
      jine: ref(""),
      us: ref(null),
      balance: ref(0)
    }
  },
  methods: {
    tolink(path) {
      this.$router.push(path)
    },
    load() {
      this.us = JSON.parse(this.$utils.getloc("us"))
      this.getWallet()
    },
    reduce() {
      if (this.jine > 0) {
        this.jine--
      }
    },
    getWallet() {
      let _this = this
      _this.$request.post(
        "api/Wallets/GetWallet",
        {
          userid: _this.us.userid
        },
        (res) => {
          if (res.data.code == 0) {
            _this.$q.dialog({
              component: CustomComponent,
              componentProps: {
                messages: [res.data.msg],
                persistent: true,
              }
            });
            return
          }
          let data = res.data.data
          let usdt = data.find(item => item.cid == 1)
          _this.balance = usdt.jine
        }
      )
    },
    touzi() {
      let _this = this
      if (_this.jine == "") {
        _this.$q.dialog({
          component: CustomComponent,
          componentProps: {
            messages: ["请输入数量"],
            persistent: true,
          }
        });
        return
      }
      _this.$q.dialog({
        component: CustomComponent,
        componentProps: {
          messages: ["确定要铸币吗?"],
          persistent: true,
          showCancel: true
        }
      }).onOk(() => {
        let web3Helper = new Web3Helper();
        _this.$q.loading.show({
          message: _this.$i18n.t("铸币中...")
        })
        web3Helper.getSign().then((signStr) => {
          _this.$request.post(
            "api/Token/Zhubi",
            {
              
              userid: _this.us.userid,
              amount: _this.jine,
              signStr: signStr,
              address: _this.us.address
            },
            (res) => {
              _this.$q.loading.hide();
              let msg = ""
              let msgList = ExtractChineseWords(res.data.msg)
              msgList.forEach(item => {
                msg += _this.$i18n.t(item)
              });
              _this.$q.dialog({
                component: CustomComponent,
                componentProps: {
                  messages: [msg],
                  persistent: true,
                }
              });
              if (res.data.code == 100) {
                _this.jine = ""
                _this.getWallet()
                _this.$emit('zhubiUpdate')
              }
            }
          )
        }).catch(() => {
          _this.$q.loading.hide();
        })
      })
    }
  }
}
</script>

<style scoped>
.mintage {
  margin-top: 15px;
  padding: 25px 15px 10px;
  border-radius: 20px;
  background-color: #2f1d51;
}

.header {
  display: flex;
  align-items: center;
}

.header-icon {
  width: 25px;
}

.title {
  margin-left: 10px;
  font-size: 18px;
  color: #fff;
}

.consume {
  margin-top: 22px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.consume-icon {
  width: 24px;
}

.consume-title {
  margin-left: 10px;
  font-size: 15px;
  color: #fff;
}

.form {
  color: #fff;
}

.input-wrap {
  margin-top: 10px;
  padding: 5px 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #0D0B18;
  border-radius: 12px;
}

.input-label {
  font-size: 15px;
  color: #999;
}

.input-row {
  display: flex;
  align-items: center;
}

.stepper-icon {
  width: 22px;
}

.input {
  margin: 0 10px;
  width: 60px;
}

.mintage-text {
  color: #e96dff;
}

.cell {
  margin-top: 4px;
  display: flex;
  justify-content: space-between;
}

.btn {
  margin-top: 20px;
  height: 50px;
  line-height: 50px;
  color: #fff;
  font-size: 18px;
  text-align: center;
  background: linear-gradient(90deg, #f56ef9 0, #8a6dff);
  border-radius: 10px;
}

.link {
  color: #e96dff;
  text-decoration: underline;
}
</style>