<template>
    <div class="record">
        <headbar :title="$t('国库')"></headbar>

        <PieCharts ref="pieChartsRef" />

        <div class="market">
            <div class="market-item">
                <img src="@/assets/img/base/usdt.svg" class="market-icon" />
                <div class="market-item-content">
                    <div class="market-item-row market-item-text">
                        <div class="market-item-row-title">{{ $t('国库共识USDT总量') }}</div>
                        <div>{{ swapInfo.pool1 }}</div>
                    </div>
                </div>
            </div>
            <div class="market-item">
                <img src="@/assets/img/logo.png" class="market-icon" />
                <div class="market-item-content">
                    <div class="market-item-row market-item-text">
                        <div class="market-item-row-title">{{ $t('国库共识铸币流通量') }}</div>
                        <div>{{ swapInfo.pool2 }}</div>
                    </div>
                    <div class="market-item-row market-item-text" >
                        <div class="market-item-row-title">{{ $t('累计销毁总量') }}</div>
                        <div @click="$router.push('BurnRecord')" style="color: #e56efa;text-decoration:underline;">{{ swapInfo.xiaohui }}</div>
                    </div>
                </div>
            </div>
        </div>

        <zhubi ref="zhubiRef" @zhubiUpdate="refresh(), $refs.pieChartsRef.getPieChartsData()"></zhubi>

        <trade ref="tradeRef" @refresh="refresh(), $refs.pieChartsRef.getPieChartsData()"
            @onClickShowCharts="(e) => { showCharts = e }"></trade>

        <q-dialog v-model="showCharts">
            <q-card class="q-dialog-plugin">
                <q-btn class="q-dialog-plugin-close" icon="close" flat round dense v-close-popup />
                <charts></charts>
            </q-card>
        </q-dialog>
    </div>
</template>
  
<script>
import { ref } from 'vue';
import Charts from '@/views/home/components/Charts.vue'
import Zhubi from '@/views/home/components/Zhubi.vue'
import Trade from '@/views/home/components/Trade.vue'
import PieCharts from './components/PieCharts.vue'
import CustomComponent from "@/components/CustomComponent";

export default {
    name: '',
    components: {
        Charts,
        Zhubi,
        Trade,
        PieCharts
    },
    setup() {
        return {
            us: ref(null),
            swapInfo: ref({}),
            showCharts: ref(false)
        }
    },
    mounted() {
        this.refresh()
    },
    methods: {
        refresh() {
            this.us = JSON.parse(this.$utils.getloc("us"))
            this.getSwapInfo()
            this.$refs.zhubiRef.load()
            this.$refs.tradeRef.load()
        },

        getSwapInfo() {
            let _this = this
            _this.$request.post(
                "api/Token/GetSwapInfo",
                {
                    
                    userid: _this.us.userid
                },
                (res) => {
                    if (res.data.code == 0) {
                        _this.$q.dialog({
                            component: CustomComponent,
                            componentProps: {
                                messages: [res.data.msg],
                                persistent: true,
                            }
                        });
                        return
                    }
                    let data = res.data.data
                    _this.swapInfo = data
                    // console.log(data)
                }
            )
        },
    }
}
</script>
  
<style scoped>
.record {
    padding: 10px;
    min-height: 100vh;
    background-color: #080535
}



.taps {
    margin-top: 20px;
    display: flex;
}

.taps-item {
    flex: 1;
    text-align: center;
    color: #fff;
}

.taps-icon {
    width: 63px;
}


.market {
    margin-top: 20px;
}

.market-item {
    margin-top: 10px;
    padding: 0 10px;
    height: 75px;
    display: flex;
    align-items: center;
    border-radius: 10px;
    background-color: #281b54;
}

.market-icon {
    width: 50px;
}

.market-item-content {
    padding-left: 10px;
    flex: 1;
}

.market-item-row {
    display: flex;
    justify-content: space-between;
}

.market-item-text {
    font-size: 18px;
    font-weight: bold;
    color: #fff;
}

.market-item-label {
    color: #a18ee1;
}

.market-item-row-title {
    display: flex;
    align-items: center;
    font-size: 13px;
    max-width: 180px;
    overflow-x: auto;
}

.q-dialog-plugin {
    position: relative;
    max-height: calc(100vh - 108px);
    background: #000;
    color: #fff;
    border: 2px solid #f56ef9;
}

.q-dialog-plugin-close {
    position: absolute;
    top: 0;
    right: 0;
}
</style>