<template>
    <div class="charts" style="margin-top: 10px;">
    <div class="charts-head">
        <img class="charts-head-img" src="@/assets/img/logo.png">
        <div class="charts-head-title">{{ $t('铸币流通占比') }}</div>
    </div>
    <div class="content">
        <div class="pie-chart-wrap">
            <div id="container" class="pie-chart"></div>
            <div class="dfx">
                <div class="dfx-amount">{{ fxl }}</div>
                <div class="dfx-label">{{ $t('国库共识待发行量') }}</div>
            </div>
        </div>
        <div class="label">
            <div class="label-item">
                <div class="cell" style="background: #2CAFFE;"></div>
                <div class="label-title">{{ titleList[0] }}</div>
            </div>
            <div class="label-item">
                <div class="cell" style="background: #5AF3B8;"></div>
                <div class="label-title">{{ titleList[1] }}</div>
            </div>
            <div class="label-item">
                <div class="cell" style="background: #ED6EF9;"></div>
                <div class="label-title">{{ titleList[2] }}</div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import { ref } from 'vue'
import CustomComponent from "@/components/CustomComponent";

import * as echarts from 'echarts'
import 'echarts-gl'

export default {
    name: '',
    components: {},
    setup() {
        return {
            dateList: ref([]),
            priceList: ref([]),
            chart: null,
            titleList: []
        }
    },
    data() {
        return {
            fxl: 0
        }
    },
    created() {
        this.titleList = [this.$t('LBD待发行量'), this.$t('LBD流通总量'), this.$t('LBD销毁总量')]
        this.getPieChartsData()
    },
    methods: {
        getPieChartsData() {
            let _this = this
            _this.$request.post(
                "api/Token/GetPieChartsData", {},
                (res) => {
                    if (res.data.code == 0) {
                        _this.$q.dialog({
                            component: CustomComponent,
                            componentProps: {
                                messages: [res.data.msg],
                                persistent: true,
                            }
                        });
                        return
                    }
                    let pieData = res.data.data
                    _this.fxl = pieData.fxl
                    const chartData = [
                        {
                            name: _this.titleList[0],
                            value: pieData.dfx,
                            color: '#2CAFFE'
                        },
                        {
                            name: _this.titleList[1],
                            value: pieData.ltl,
                            color: '#5AF3B8'
                        },
                        {
                            name: _this.titleList[2],
                            value: pieData.xhl,
                            color: '#ED6EF9'
                        }
                    ]
                    const option = _this.get3DPieChartOption(chartData)
                    _this.chart = echarts.init(document.getElementById('container'))
                    _this.chart.setOption(option)
                }
            )
        },
        get3DPieChartOption(pieData) {
            let series = []
            const k = 0.16
            const sumValue = pieData.reduce((c, n) => c + n.value * 1, 0)
            const values = pieData.map(item => item.value * 1)
            const sortedValues = values.sort((a, b) => b - a)
            const maxValue = sortedValues[0]
            let prevEndRatio = 0
            // 为每一个饼图数据，生成一个 series-surface 配置
            for (let i = 0; i < pieData.length; i++) {
                const item = pieData[i]
                const startRatio = prevEndRatio
                prevEndRatio += item.value * 1 / sumValue
                const endRatio = prevEndRatio
                const h = 20 / maxValue * (item.value * 1)

                const seriesItem = {
                    name: item.name,
                    type: 'surface',
                    parametric: true,
                    silent: true,
                    wireframe: {
                        show: false
                    },
                    pieData: {
                        name: item.name,
                        value: item.value
                    },
                    itemStyle: {
                        color: item.color
                    },
                    parametricEquation: this.getParametricEquation(startRatio, endRatio, false, false, k, h)
                }
                series.push(seriesItem)
            }

            const option = {
                backgroundColor: '#2F1D51',
                xAxis3D: {
                    min: -1,
                    max: 1
                },
                yAxis3D: {
                    min: -1,
                    max: 1
                },
                zAxis3D: {
                    min: -1,
                    max: 1
                },
                grid3D: {
                    show: false,
                    boxHeight: 10,
                    viewControl: {
                        alpha: 30,
                        distance: 150,
                        // center: [90, 90, 90],
                        zoomSensitivity: false
                    }
                },
                series: [
                    ...series,
                    {
                        name: 'pie-label',
                        type: 'pie',
                        data: pieData.map(item => ({
                            name: item.value,
                            value: item.value,
                            itemStyle: {
                                color: item.color
                            }
                        })),
                        startAngle: -10,
                        clockwise: false,
                        label: {
                            color: '#ffffff',
                            opacity: 1,
                            overflow: 'break'
                        },
                        labelLine: {
                            length: 20,
                            length2: 10
                        },
                        itemStyle: {
                            opacity: 0
                        },
                        top: 10
                    }
                ]
            }
            return option
        },
        getParametricEquation(startRatio, endRatio, isSelected, isHovered, k, h) {
            let midRatio = (startRatio + endRatio) / 2
            let startRadian = startRatio * Math.PI * 2
            let endRadian = endRatio * Math.PI * 2
            let midRadian = midRatio * Math.PI * 2
            if (startRatio === 0 && endRatio === 1) {
                isSelected = false
            }
            // 通过扇形内径/外径的值，换算出辅助参数 k（默认值 1/3）
            k = typeof k !== 'undefined' ? k : 1 / 3
            // 计算选中效果分别在 x 轴、y 轴方向上的位移（未选中，则位移均为 0）
            let offsetX = isSelected ? Math.cos(midRadian) * 0.1 : 0
            let offsetY = isSelected ? Math.sin(midRadian) * 0.1 : 0
            // 计算高亮效果的放大比例（未高亮，则比例为 1）
            let hoverRate = isHovered ? 1.05 : 1
            // 返回曲面参数方程
            return {
                u: {
                    min: -Math.PI,
                    max: Math.PI * 3,
                    step: Math.PI / 32
                },

                v: {
                    min: 0,
                    max: Math.PI * 2,
                    step: Math.PI / 20
                },

                x: function(u, v) {
                    if (u < startRadian) {
                        return offsetX + Math.cos(startRadian) * (1 + Math.cos(v) * k) * hoverRate
                    }
                    if (u > endRadian) {
                        return offsetX + Math.cos(endRadian) * (1 + Math.cos(v) * k) * hoverRate
                    }
                    return offsetX + Math.cos(u) * (1 + Math.cos(v) * k) * hoverRate
                },

                y: function(u, v) {
                    if (u < startRadian) {
                        return offsetY + Math.sin(startRadian) * (1 + Math.cos(v) * k) * hoverRate
                    }
                    if (u > endRadian) {
                        return offsetY + Math.sin(endRadian) * (1 + Math.cos(v) * k) * hoverRate
                    }
                    return offsetY + Math.sin(u) * (1 + Math.cos(v) * k) * hoverRate
                },

                z: function(u, v) {
                    if (u < -Math.PI * 0.5) {
                        return Math.sin(u)
                    }
                    if (u > Math.PI * 2.5) {
                        return Math.sin(u) * h * .1
                    }
                    return Math.sin(v) > 0 ? 1 * h * .1 : -1
                }
            }
        }
    }
}
</script>

<style scoped>
.charts {
    border-radius: 10px;
    color: #fff;
    background-color: #2f1d51;
}

.content {
    padding: 10px;
}

.pie-chart-wrap {
    height: 220px;
    position: relative;
}

.pie-chart {
    position: absolute;
    top: -25px;
    left: 0;
    right: 0;
    height: 220px;
}

.dfx {
    position: absolute;
    bottom: 110px;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    color: #ffffff;
}

.dfx-amount {
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
}

.dfx-label {
    font-size: 12px;
    line-height: 20px;
    transform: scale(0.83);
    transform-origin: center top;
}

.echarts {
    margin-top: 20px;
    width: 100%;
    height: 250px;
}

.price {
    text-align: center;
    color: #fff;
    font-size: 15px;
}

.price span {
    color: #e56efa;
}

.charts-head {
    display: flex;
    align-items: center;
    padding: 15px 15px 10px;
}

.charts-head-img {
    width: 25px;
    margin-right: 5px;
}

.charts-head-title {
    font-size: 18px;
}

.label {
    display: flex;
    justify-content: center;
    align-items: center;
}

.label-item {
    display: flex;
    align-items: center;
    margin-right: 10px;
}

.cell {
    background: red;
    width: 10px;
    height: 10px;
    margin-right: 5px;
}

.label-title {
    font-size: 12px;
    max-width: 100px;
    overflow-x: auto;
}
</style>
